<template>
  <div class="analysis">
    <div class="analysis_top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>学习中心</el-breadcrumb-item>
        <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="test">
        <div class="name">{{ title }}</div>
        <div class="tip">
          试卷说明：本试卷共<span>{{ 12 }}</span
          >道题，满分<span>{{ 12 }}</span
          >分，考试时间<span>{{ 12 }}</span
          >分钟
        </div>
      </div>
    </div>
    <div class="test_details">
      <div class="detail">
        <div class="detail_name">
          <div class="name">
            {{ queTypeNum[typeIndex] }}、{{
              queType[subList[queCarBoxIndex]?.questionType]
            }}
            <!-- <span class="grade" v-if="">（2分）</span> -->
          </div>
          <div class="collect" @click="collecttestquestions()">
            <i
              v-if="collectList.includes(testQuestionsId)"
              style="color: #ff952a"
              class="el-icon-star-on"
            />
            <i v-else class="el-icon-star-off" />
            收藏
          </div>
        </div>
        <div class="detail_analysis">
          <div
            v-for="(item, index) of subList"
            v-show="queCarBoxIndex == index"
            ref="queCarBox"
            :key="index"
            class="ti"
          >
            <que-car
              v-if="ifNew"
              ref="queCar"
              :paper-id="paperId"
              :is-purchase="IsPurchase"
              :see-num="seeNum"
              :start="start"
              :is-points="isPoints"
              :is-submited="isSubmit"
              :detail="item"
              :exam-type="examType"
              :detail-index="index"
              :que-car-box-index="queCarBoxIndex"
              @getShitiList="getShitiList"
              @NextTopic="NextTopic"
            />
            <!-- @task="getNum" -->
          </div>
          <div class="dis_fle_between">
            <el-button
              class="queCarPrev"
              :disabled="queCarBoxIndex == 0"
              @click="previousTopic"
              ><i class="iconfont el-icon-arrow-left" /> 上一题</el-button
            >
            <el-button
              v-if="queCarBoxIndex != subList.length - 1"
              class="queCarNext"
              @click="NextTopic"
              >下一题 <i class="iconfont el-icon-arrow-right"
            /></el-button>
          </div>
        </div>
      </div>
      <div class="answer">
        <div class="details_right">
          <div class="time">
            <el-image
              class="img"
              :src="require('@/assets/img/partner/time3.png')"
            ></el-image>
            <span class="time_title">总用时：{{ 12 }}</span>
          </div>
          <div class="on">
            <el-image
              class="img"
              :src="require('@/assets/img/partner/report3.png')"
            ></el-image>
            <span class="time_title">主观题不进行判分，请参照答案</span>
          </div>
          <div class="goback" @click="gobackClick">
            <el-image
              class="img"
              :src="require('@/assets/img/partner/goback.png')"
            ></el-image>
            <span class="time_title bule">返回学习</span>
          </div>
          <div class="goback">
            <el-image
              class="img"
              :src="require('@/assets/img/partner/report2.png')"
            ></el-image>
            <span class="time_title">查看报告</span>
          </div>
          <div class="goback active">
            <el-image
              class="img"
              :src="require('@/assets/img/partner/analysis3.png')"
            ></el-image>
            <span class="time_title">全部解析</span>
          </div>
          <div class="goback">
            <el-image
              class="img"
              :src="require('@/assets/img/partner/analysis.png')"
            ></el-image>
            <span class="time_title">错题解析</span>
          </div>
        </div>
        <div class="answer_sheet">
          <div class="answer_sheet_top">
            <el-image
              class="img"
              :src="require('@/assets/img/partner/card.png')"
            ></el-image>
            <span>答题卡</span>
          </div>
          <div class="item">
            <span
              v-for="(item, index) in cardList"
              :class="{
                item2: item.isCorrect === 0 || item.isCorrect === null,
                item1: item.isCorrect === 1,
                item3: item.isCorrect === 2,
              }"
              >{{ index + 1 }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queType, queTypeNum } from "@/api/emun";
import { getSubjectIdList } from "@/api/course/course";
import AnswerSheet from "../../../quesTion/answerSheet.vue";
import QueCar from "./queCar.vue";
export default {
  data() {
    return {
      queTypeNum: queTypeNum,
      queType: queType,
      typeIndex: 1,
      queCarBoxIndex: 0,
      isSubmit: true, // 是否显示解析
      typeTotal: 20,
      subjectTotal: 100,
      testQuestionsId: 1,
      subList: [], // 题目列表-提交时用
      allLists: [], // 根据题型重组的数组
      collectList: [], // 收藏试题列表
      wrongTopic: false,
      title: "",
      ifNew: true,
      paperId: "", // 试卷ID
      IsPurchase: true, // 是否购买
      /* 试看 */
      seeNum: null,
      start: false, // 是否计时
      isPoints: false, // 是否显示得分
      examType: 3,
      cardList: [],
    };
  },
  components: {
    AnswerSheet,
    QueCar,
    /* ExamFeedBack, ExamAnalysis */
  },
  created() {
    this.title = this.$route.query.title;
    this.getShitiList();
  },
  methods: {
    // 收藏试题
    async collecttestquestions() {
      const isIncloud = this.collectList.find((item) => {
        if (item === this.testQuestionsId) {
          return item === this.testQuestionsId;
        }
      });
      if (isIncloud) {
        this.collectList = this.collectList.filter(
          (item) => item !== this.testQuestionsId
        );
      } else {
        this.collectList.push(this.testQuestionsId);
      }
    },
    async getShitiList() {
      const cres = await getSubjectIdList(JSON.parse(this.$route.query.param));
      console.log(cres);
      this.cardList = cres.data;
      this.Splicing(cres.data);
    },
    /* 试题拼接 */
    Splicing(list, ans) {
      this.TiXing = [0, 0, 0, 0, 0, 0];
      /* ans  是否是提交显示的 */
      let shiti = [];
      for (const item of list) {
        item.questionType = Number(item.questionType);
        if (
          item.questionType == 0 ||
          item.questionType == 1 ||
          item.questionType == 6
        ) {
          const data = JSON.parse(item.questionOption);
          const sub = [];
          for (const i in data) {
            const val = {
              val: data[i],
              num: String.fromCharCode(Number(i) + 65),
            };
            sub.push(val);
          }
          item.optionList = sub;
          if (ans && (item.questionType == 1 || item.questionType == 6)) {
            item.questionUserAnswer = [];
          }
          if (!ans && (item.questionType == 1 || item.questionType == 6)) {
            if (
              item.questionUserAnswer &&
              item.questionUserAnswer.length > 0 &&
              item.questionUserAnswer instanceof Array
            ) {
              // item.questionUserAnswer = item.questionUserAnswer.split(",");
            } else {
              item.questionUserAnswer = [];
            }
          }
          shiti.push(item);
        } else if (item.questionType == 2) {
          item.optionList = [
            { val: "对", num: "A" },
            { val: "错", num: "B" },
          ];
          shiti.push(item);
        } else if (item.questionType == 5) {
          if (item.childSubject) {
            for (const chil of item.childSubject) {
              chil.questionType = Number(chil.questionType);
              if (
                chil.questionType == 0 ||
                chil.questionType == 1 ||
                chil.questionType == 6
              ) {
                const data = JSON.parse(chil.questionOption);
                const sub = [];
                for (const i in data) {
                  const val = {
                    val: data[i],
                    num: String.fromCharCode(Number(i) + 65),
                  };
                  sub.push(val);
                }
                chil.optionList = sub;
                if (ans && (chil.questionType == 1 || chil.questionType == 6)) {
                  chil.questionUserAnswer = [];
                }
                if (
                  !ans &&
                  (chil.questionType == 1 || chil.questionType == 6)
                ) {
                  if (chil.questionUserAnswer) {
                    chil.questionUserAnswer =
                      chil.questionUserAnswer.split(",");
                  } else {
                    chil.questionUserAnswer = [];
                  }
                }
              } else if (chil.questionType == 2) {
                chil.optionList = [
                  { val: "对", num: "A" },
                  { val: "错", num: "B" },
                ];
              }
            }
            shiti = shiti.concat(item.childSubject);
          }
        } else {
          shiti.push(item);
        }
      }
      this.subjectList = shiti;
      this.testQuestionsId = shiti[0].id;
      for (let i = 0; i < 7; i++) {
        this.allLists.push(
          shiti.filter((item, index) => {
            if (item.questionType === i) {
              item.subjectIndex = index;
              return item;
            }
          })
        );
      }
      this.time = {
        hours: parseInt(this.examTime / 60),
        minutes: this.examTime - 60 * parseInt(this.examTime / 60) - 1,
        seconds: 60,
      };
      this.allLists = this.allLists.filter((s, index) => {
        if (index === 0) {
          //
          this.typeIndex = 0;
          this.typeTotal = s.length;
          let fraction = 0;
          s.map((item) => {
            fraction += item.score;
          });
          this.subjectTotal = fraction;
        }
        return s.length != 0;
      });
      this.subList = list; // 提交时用  同数据源数据会一起变化
      // 添加 字段
      for (const i in this.subjectList) {
        const item = this.subjectList[i];
        item.indexVal = Number(i) + 1;
        this.TiXing[item.questionType]++;
      }
    },
    // 返回学习
    gobackClick() {
      history.back();
    },
    // 上一题
    previousTopic() {
      this.next = false;
      if (this.queCarBoxIndex == 0) {
        return;
      } else {
        this.queCarBoxIndex--;
        this.testQuestionsId = this.subjectList[this.queCarBoxIndex].id;
        if (
          this.subjectList[this.queCarBoxIndex].questionType !==
          this.subjectList[this.queCarBoxIndex + 1].questionType
        )
          this.typeIndex--;
        this.jumpShiTi(
          this.testQuestionsId,
          this.typeIndex,
          this.queCarBoxIndex
        );
      }
    },
    // 下一题
    NextTopic(val) {
      if (this.queCarBoxIndex >= this.subjectList.length - 1) {
        return;
      } else {
        this.queCarBoxIndex++;
        if (this.maxIndex <= this.queCarBoxIndex) {
          this.maxIndex = this.queCarBoxIndex;
          this.next = true;
        }
        if (this.queCarBoxIndex == this.subjectList.length - 1) {
          this.next = true;
        }
        this.testQuestionsId = this.subjectList[this.queCarBoxIndex].id;
        if (
          this.subjectList[this.queCarBoxIndex].questionType !==
          this.subjectList[this.queCarBoxIndex - 1].questionType
        )
          this.typeIndex++;
        this.jumpShiTi(
          this.testQuestionsId,
          this.typeIndex,
          this.queCarBoxIndex
        );
      }
    },
    // 跳转试题
    jumpShiTi(id, index, subjectIndex) {
      this.testQuestionsId = id;
      this.queCarBoxIndex = subjectIndex;
      this.typeIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.analysis {
  width: 100%;
  .analysis_top {
    width: 1200px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin: 20px auto 0;
    padding: 24px 32px 22px 29px;
    .test {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      .name {
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .tip {
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .test_details {
    width: 1200px;
    border-radius: 6px;
    opacity: 1;
    margin: 20px auto 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .detail {
      width: 974px;
      background: #ffffff;
      border-radius: 6px;
      opacity: 1;
      .detail_name {
        width: 974px;
        height: 64px;
        background: #f9fbff;
        border-radius: 6px 6px 0px 0px;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 20px;
        .name {
          font-size: 14px;
          color: #1c81f5;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          .grade {
            font-size: 12px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }
        .collect {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
        }
      }
      .detail_analysis {
        position: relative;
        // .ti {
        //
        // }
        .dis_fle_between {
          margin-top: 50px;
          width: 100%;
          .queCarNext,
          .queCarPrev {
            flex: 1;
            margin-left: 0;
            width: 50%;
          }
        }
      }
    }
    .answer {
      width: 206px;
      opacity: 1;
      margin-left: 20px;
      .details_right {
        width: 207px;
        height: 300px;
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        .time {
          width: 207px;
          height: 60px;
          background: #f9fbff;
          border-radius: 6px 6px 0px 0px;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
          .time_title {
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #666666;
          }
        }
        .goback {
          width: 207px;
          height: 50px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }
          .time_title {
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
          }
          .bule {
            color: #1c81f5;
          }
        }
        .goback:nth-last-child(1) {
          border-top: 1px solid #eeeeee;
        }
        .active {
          background: #1c81f5;
          .time_title {
            color: #ffffff;
          }
        }
        .on {
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(255, 93, 81, 0.1);
          .img {
            width: 12px;
            height: 13px;
            margin-right: 6px;
          }
          .time_title {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ff5d51;
            padding: 10px 0;
          }
        }
      }
      .answer_sheet {
        width: 207px;
        // height: 319px;
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        margin-top: 20px;
        padding: 20px 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .answer_sheet_top {
          // text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }
        }
        .item {
          margin-top: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          .item1,
          .item2,
          .item3{
            // margin-top: 14px;
            width: 22px;
            height: 22px;
            border-radius: 3px 3px 3px 3px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            margin: 14px 7px 0;
          }
          .item1 {
            background: #13bc75;
          }
          .item2 {
            background: #ee5729;
          }
          .item3 {
            background: #30a7e5;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
